@font-face {
  font-family: "PoppinsRegular";
  src: url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "PoppinsSemibold";
  src: url("./assets/fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "PoppinsMedium";
  src: url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "PoppinsBold";
  src: url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
}

/* customizing scrollbar */

::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 20px;
  border: transparent;
}

::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
}

/* Customizing the toast container */
.Toastify__toast-container {
  min-width: fit-content;
  width: fit-content !important;
  border-radius: 12px;
}

/* Customizing toast based on type */
.Toastify__toast--success {
}
.Toastify__toast--error {
}
.Toastify__toast--info {
}
.Toastify__toast--warning {
}

/* Customizing the toast itself */
.Toastify__toast {
  border: 1px solid #22c55e;
  background: #e7f8f0;
  padding: 16px;
  align-items: center;
}

/* Customizing the close button */
.Toastify__close-button {
  color: white;
  align-self: center;
}
.Toastify__close-button > svg {
  width: 20px;
  height: 20px;
}
